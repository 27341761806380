.appBarContent{
  display: flex;
  align-items: center;
  &ToggleDrawerButton {
    color: #fff;
    margin-left: 1rem;
  }
}

.main{
  flex-grow: 1;
  margin-top: 60px;
  padding: 1.5rem;
  transition: var(--transition-timing) ease-out;
  &DrawerOpen {
    margin-left: 240px;
  }
}

.logo{
  width: 120px;
  height: 100%;
  margin-left: 1.5rem;
  img{
    width: 100%;
    height: 100%;
  }
}
