.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}