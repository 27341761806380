.login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 1rem;
  &Title{
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  &Subtitle{
    color: var(--text-color-75);
    margin-top: 0;
    font-weight: 500;
    text-align: center;
  }
  &Alert{
    margin-bottom: 0.7rem;
  }
  &Logo{
    height: 60px;
    margin: 0 auto 1rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  &Form{
    margin-top: 1rem;
    width: 350px;
  }
  &SendAgain{
    display: block;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    font-weight: 500;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    &:focus-visible{
      outline: 1px solid var(--text-color);
    }
    &:focus{
      outline: 1px solid var(--text-color);
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    &Logo {
      height: 45px;
    }
    &Form {
      width: 100%;
    }
  }
}