$spinner-color: #fff !default;
$spinner-size: 50px !default;

.overlay {
  transition: var(--transition-timing);
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1501;
  background: rgba(0, 0, 0, 0.3);
}


.spinner {
  font-size: $spinner-size;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;

  &.center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 80px;
    height: 80px;
  }

  .spinnerBlade {
    position: absolute;
    left: .4629em;
    bottom: 0;
    width: .074em;
    height: .2777em;
    border-radius: .0555em;
    background-color: transparent;
    transform-origin: center -.2222em;
    animation: spinnerFade 1s infinite linear;
    $animation-delay: 0s;
    $blade-rotation: 0deg;
    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: $animation-delay;
        transform: rotate($blade-rotation);
        $blade-rotation: $blade-rotation + 30;
        $animation-delay: $animation-delay + .083;
      }
    }
  }

}


@keyframes spinnerFade {
  0% {
    background-color: $spinner-color;
  }
  100% {
    background-color: transparent;
  }
}
