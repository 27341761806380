.input {
  display: flex;
  justify-content: space-between;
  &Item {
    position: relative;
    width: 80px;
    border: 1px solid var(--text-color-75);
    border-radius: 4px;
    background: transparent;
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    margin: 0.5rem 0;
    color: var(--text-color);
    transition: .2s ease-in-out;
    &::placeholder {
      font-size: 36px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: var(--text-color-35);
    }
  }
}

@media screen and (max-width: 768px) {
  .input {
    &Item {
      width: 60px;
    }
  }
}