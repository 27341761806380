@tailwind base;
@tailwind components;
@tailwind utilities;

*, :after, :before{
  box-sizing: border-box;
}

body{
  font-family: 'Roboto', "sans-serif";
  color: var(--text-color);
}

:root{
  --primary-color: #0B5534;
  --primary-color-75: #488067;
  --primary-color-50: #85AA9A;
  --primary-color-35: #AAC3B8;
  --primary-color-25: #C2D4CC;
  --primary-color-10: #E7EEEB;
  --secondary-color: #67B159;
  --secondary-color-75: #4DB84E;
  --secondary-color-50: #7ACA7A;
  --secondary-color-35: #A6DCA7;
  --secondary-color-25: #D2EDD3;
  --secondary-color-10: #EEF8EE;
  --text-color: #231F20;
  --text-color-75: #5A5758;
  --text-color-50: #918F90;
  --text-color-35: #B2B1B1;
  --text-color-25: #C8C7C7;
  --text-color-10: #E9E9E9;
  --orange: #FCB040;
  --purple: #652D90;
  --turquoise: #ED008C;
  --green: #00C48C;
  --text-color: #231F20;
  --contrast-color-80: rgba(255, 255, 255, 0.8);
  --contrast-color-40: rgba(255, 255, 255, 0.4);
  --transition-timing: .2s;
  --transition-animation: ease-in-out;
  --out-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#portal-root{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1201;
  display: none;
}

.hidden-preloader {
  opacity: 0;
  z-index: -1;
}
