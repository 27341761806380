.drawer {
  padding: 0 0.475rem;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: var(--text-color-10);
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--text-color-25);
    border-radius: 0.5rem;
  }
}

.menu {
  margin: 1rem 0;
  list-style: none;
  flex-grow: 1;
  padding: 0;
  border-radius: inherit;

  &Item {
    padding: 1rem 0.8rem;
    transition: var(--transition-timing) var(--transition-animation);
    border-radius: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &Child {
      display: flex;
      padding: 0.5rem 0.8rem 0.5rem 1.2rem;
      transition: .1s;
      &:hover{
        color: var(--text-color-50);
      }
      span {
        margin-right: 0.75rem;
      }
    }

    &Active {
      background: var(--text-color-25);
      border-radius: 8px;
      &:hover {
        .menuItemChild {
          color: var(--text-color);
        }
      }
    }
  }

  &Icon{
    transition: .2s;
    transform: rotate(90deg);
  }

  &Avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40%;
    background: #E0E0E4;
    padding: 1.5rem 1rem;
    border-radius: inherit;
  }

  &FullName {
    color: var(--text-color);
    margin-top: 0.5rem;
    text-align: center;
  }
}

.bottomMenu {
  list-style: none;
  padding: 0;
  margin-bottom: 20%;
  border-radius: inherit;
}

.expand.menuIcon {
  transform: rotate(-90deg);
}
