.box {
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: var(--out-shadow);
  padding: 1rem;

  .heading {
    font-size: 1.25rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }

  .products {
    margin-top: 0.75rem;
  }
}

.product {
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--text-color-25);

  &:last-child {
    border-bottom: none;
  }

  &Image {
    width: 100px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid var(--text-color-25);
    margin-right: 0.75rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &Information {
    flex-grow: 1;
  }

  &Title {
    font-weight: 500;
  }

  &Subtitle {
    color: var(--text-color-75);

    span {
      font-weight: 500;
      color: var(--text-color);
    }
  }
}

.service {
  &Title {

  }

}

.customerInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.orderLayout {
  padding: 1rem;
  background: #fff;
  box-shadow: var(--out-shadow);
  margin-bottom: 1rem;
  border-radius: 0.5rem;

  &Item {
    display: flex;
    width: 100%;
    margin-top: 0.75rem;

    p {
      width: 50%;
      color: var(--text-color-75);
    }

    span {
      display: block;
      width: 50%;
    }
  }

  .long {
    justify-content: space-between;

    p {
      width: auto !important;
    }
  }
}

.heading {
  font-weight: 600;
  font-size: 1.125rem;
  display: flex;
  justify-content: space-between;
}

.products {
  .heading {
    font-weight: 500;
    margin-top: 0.75rem;
    font-size: 1.05rem;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    margin: 0.75rem 0;
    color: var(--text-color-75);

    span {
      color: var(--text-color);
    }
  }

  .link {
    //font-style: italic;
    color: blue;
  }
}

.dividedByTwo {
  display: flex;
  justify-content: space-between;

  h3 {
    color: var(--text-color-75);
  }

  .column {
    width: 48%;

    .products {
      h4 {
        font-size: 1rem;
        line-height: 125%;
      }

      .list {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        margin: 0.5rem 0;
      }
    }
  }
}

.orderHistoryHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.orderHistoryBody {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 16px;

  .item {
    margin-top: 5px;
    display: flex;

    .line {
      display: flex;
      justify-content: center;
      margin-right: 25px;
      width: 2px;
      background: #B6BCC3;

      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 12px;
        background: #B6BCC3;
        border-radius: 100%;
        font-size: 14px;
        color: #fff;
      }
    }

    .info {
      padding-bottom: 15px;
      line-height: 24px;
      width: 100%;
      &Heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &Button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        max-width: 24px;
        height: 24px;
        svg {
          width: 18px;
          height: 18px;
        }

      }
      span {
        display: block;
        font-size: 13px;
        line-height: 16px;
        color: #546272;
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .checkedIconContainer {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;

    .iconLine {
      border-left: 1px solid #dbdee1;
      flex: 1 1;
    }
  }
}


.orderTransfers {
  .loader {
    display: flex;
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .warehouseTabsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .warehouseTab {
      border: 1px solid #b6bcc3;
      border-radius: 32px;
      padding: 6px 16px;
      cursor: pointer;

      &:hover {
        background-color: #dbdee1;
      }

      &.active {
        background-color: #0b1f35;
        color: #fff;
      }
    }
  }
}

.orderTransferProducts {
  .productItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
  
    .productMainInfo {
      display: flex;
      align-items: center;
      gap: 10px;
  
      .productImg {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        object-fit: contain;
        object-position: center;
        overflow: hidden;
        border: 1px solid var(--color-dark-20);
      }
    }
  
    .productOtherInfo {
      display: flex;
      gap: 10px;
  
      .infoItem {
        flex: 1 1;
        display: flex;
        gap: 10px;   
        .dashed {
          flex: 1 1;
          border-bottom: 1px dashed #dbdee1;
        }
        .label {
          color: #546272;
        }
      }
    }
  }
}

.orderTransferDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .mainBlock, .additionalBlock {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #f8fbff;
    border-radius: 8px;
  }

  .infoItem {
    display: flex;
    flex-direction: column;
  
    .label {
      color: #546272;
    }
  }
}


.orderTransferEventInfo {
  .loadMoreBtn {
    margin-top: 10px;
  }
}

.kaspiLink{
  text-decoration: underline;
  color: #007AFF;
  font-style: italic;
}