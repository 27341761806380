.container{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.wrapper {
  padding: 12px 16px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-left: 2px solid #4db84e;
  background: #eef8ee;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h3 {
    font-size: 16px;
    font-weight: 700;
  }

  > a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    font-style: italic;
    color: #2E90FA;
    &:hover {
      text-decoration: underline;
    }
  }

  .moreBtn {
    background: none;
    outline: none;
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid #6d7986;
    width: fit-content;
    margin-top: 8px;
  }
}
.link {
}